import { DateTime, Zone } from 'luxon'
import { useQuery } from '@tanstack/react-query'
import { ErrorResponse } from '@citruscamps/citrus-client'
import { HttpClient } from '../../../utils/http'
import { EventFilterProps } from '../../../hooks/useFetchEvents'
import { EventDaySummary } from '../../../interfaces/EventDaySummary'
import { toQueryFromFilter } from '../../../utils/event-filter-utils'
import { generateItemKey } from '../../../utils/key-generator'
import { toQueryParams } from '../../../utils/query-params'

// These are made available so that the first day is Sunday
export const getStartOfWeek = (dt: DateTime): DateTime =>
  (dt.weekday === 7 ? dt : dt.startOf('week').minus({ day: 1 })).startOf('day')
export const getEndOfWeek = (dt: DateTime): DateTime =>
  getStartOfWeek(dt).plus({ day: 6 }).endOf('day')

export const toDateTime = (
  d: Date | string,
  zone: Zone | string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): DateTime => {
  if (typeof d === 'string') {
    return DateTime.fromJSDate(new Date(d), { zone })
  } else {
    return DateTime.fromJSDate(d, { zone })
  }
}

interface IProps {
  programId: string
  filter?: Partial<EventFilterProps>
  overwriteFilters?: boolean
}

interface FetchedData {
  data: EventDaySummary[]
  isLoading?: boolean
  isError?: boolean
  error: ErrorResponse | null
}

export const useFetchWeek = ({ programId, filter }: IProps): FetchedData => {
  const query: Partial<EventFilterProps> = { ...filter, scheduled: { ...filter?.scheduled } }
  if (query.scheduled?.gt) {
    query.scheduled.gt = getStartOfWeek(DateTime.fromJSDate(query.scheduled.gt)).toJSDate()
  }
  if (query.scheduled?.lt) {
    query.scheduled.lt = getEndOfWeek(DateTime.fromJSDate(query.scheduled.lt)).toJSDate()
  }
  if (query.scheduled?.gte) {
    query.scheduled.gte = getStartOfWeek(DateTime.fromJSDate(query.scheduled.gte)).toJSDate()
  }
  if (query.scheduled?.lte) {
    query.scheduled.lte = getEndOfWeek(DateTime.fromJSDate(query.scheduled.lte)).toJSDate()
  }
  const queryKeys = generateItemKey({
    type: 'event_week',
    id: programId,
    query,
  })

  const {
    data: resp,
    isInitialLoading: isLoading,
    isError,
    error,
  } = useQuery<EventDaySummary[], ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new HttpClient({ baseUrl: '' })
      const q = toQueryParams(toQueryFromFilter(filter))
      let resp: EventDaySummary[] = await client.get(`/api/week/${programId}?${q}`, { signal })
      resp = resp.sort((a, b) => (a.iso_date > b.iso_date ? 1 : -1))
      return resp
    },
    { enabled: !!programId },
  )

  return {
    data: resp || [],
    isLoading,
    isError,
    error,
  }
}
