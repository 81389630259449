import { faGlobe, faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Select, {
  components,
  ControlProps,
  CSSObjectWithLabel,
  OnChangeValue,
  OptionProps,
  Props,
  SingleValueProps,
} from 'react-select'
import { ExplorePreferences, ExploreProgram, Venue } from '@citruscamps/citrus-client'
import { EventFilterProps } from '../../../hooks/useFetchEvents'
import {
  addAlpha,
  DefaultDark,
  DefaultLight,
  getSafeBrandColor,
} from '../../../utils/branding-utils'
import { findMatchVenue } from '../../../utils/findMatchVenue'
import { AppointmentTypeFilterProps } from '../../ExploreAppointments/hooks/useFetchAppointmentTypes'

export const getCustomStyles = (preferences?: ExplorePreferences): Partial<any> => {
  const brand_accent_color: string = getSafeBrandColor(preferences)
  return {
    control: (base: CSSObjectWithLabel, props: ControlProps<any, any>) => ({
      ...base,
      border: 'none',
      boxShadow: 'none',
    }),
    option: (base: CSSObjectWithLabel, props: OptionProps<any, any>) => ({
      ...base,
      backgroundColor: props.isSelected ? brand_accent_color : 'transparent',
      '&:hover': {
        backgroundColor: addAlpha(brand_accent_color, 0.25),
      },
      color: props.isSelected ? DefaultLight : props.isFocused ? brand_accent_color : DefaultDark,
    }),
    indicatorSeparator: (base: CSSObjectWithLabel) => ({
      ...base,
      display: 'none',
    }),
    indicatorContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      color: `#${brand_accent_color}`,
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      borderBottom: `solid 1px #${brand_accent_color}`,
      padding: 0,
      width: '100%',
      color: `#${brand_accent_color}`,
      maxHeight: '40px',
    }),
    placeholder: (base: CSSObjectWithLabel) => {
      return {
        ...base,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '100%',
      }
    },
    singleValue: (base: CSSObjectWithLabel, props: SingleValueProps<any>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      return { ...base, opacity, transition, width: '100%', color: `#${brand_accent_color}` }
    },
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: '3.125rem',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      borderRadius: '3.125rem',
    }),
  }
}

const { Control, Option, ValueContainer } = components

const CustomValueContainer = ({ children, ...props }: any) => (
  <ValueContainer {...props}>
    <span className="h2 text-primary d-inline w-100 d-flex">
      {React.Children.map(children, (child) => child || null)}
    </span>
  </ValueContainer>
)

const CustomControl = ({ children, ...props }: any) => (
  <Control {...props}>{React.Children.map(children, (child) => child || null).reverse()}</Control>
)

const VenueSelectRow = ({ label, value }: VenueOption) =>
  typeof value === 'string' ? (
    <div className="row mx-0">
      <div className="col-1 text-center py-2">
        <FontAwesomeIcon icon={faGlobe} />
      </div>
      <div className="col py-2">{label}</div>
    </div>
  ) : (
    <div className="row mx-0">
      <div className="col-1 text-center py-2">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </div>
      <div className="col">
        <div>{value?.city}</div>
        <div className="small">{[value?.region, value?.country].filter((a) => !!a).join(', ')}</div>
      </div>
    </div>
  )

const CustomeOptionContainer = ({ children, data: { value, label }, ...props }: any) => (
  <Option {...props}>{!!value && <VenueSelectRow value={value} label={label} />}</Option>
)

interface VenueOption {
  value?: Venue | string
  label: string
}

type PropsExtended = Omit<Omit<Props<VenueOption, false>, 'value'>, 'onChange'>

interface IProps extends PropsExtended {
  values?: Partial<EventFilterProps | AppointmentTypeFilterProps>
  hasOnline?: boolean
  venues?: Venue[]
  program: ExploreProgram
  onChange?: (value: EventFilterProps | AppointmentTypeFilterProps) => void | Promise<void>
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void | Promise<void>
}

export const VenueSelect = ({
  values,
  hasOnline,
  program,
  venues = [],
  onChange: handleChange,
  ...props
}: IProps) => {
  const venueOptions: VenueOption[] = venues.map((venue: Venue) => {
    return {
      value: venue,
      label: venue?.city,
    }
  })
  if (hasOnline || values?.is_online_event) {
    venueOptions.push({ value: 'online_event', label: 'Online Events' })
  }
  const venue = findMatchVenue(values?.place_name, venues)
  const value: VenueOption | null =
    values?.place_name || values?.is_online_event
      ? venueOptions.find((l) =>
          values?.is_online_event || typeof l.value === 'string'
            ? l.value === 'online_event'
            : values.place_name && l?.value && venue?.id === l.value.id,
        ) || null
      : null
  return (
    <Select
      {...props}
      options={venueOptions}
      value={value}
      placeholder="All locations"
      isSearchable={false}
      menuPlacement="auto"
      menuPosition="fixed"
      styles={getCustomStyles(program.preferences)}
      components={{
        Control: CustomControl,
        Option: CustomeOptionContainer,
        ValueContainer: CustomValueContainer,
      }}
      onChange={(option: OnChangeValue<VenueOption, false>) => {
        const online_event: boolean | undefined =
          typeof option?.value === 'string' ? option?.value === 'online_event' : undefined
        const _venue: Venue | undefined =
          typeof option?.value === 'string' ? undefined : option?.value
        handleChange?.({ ...values, place_name: _venue?.city, is_online_event: online_event })
      }}
    />
  )
}
