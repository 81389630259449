import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DateTime, Interval } from 'luxon'
import React from 'react'
import { getStartOfWeek, getEndOfWeek } from '../ExploreEvents/hooks/useFetchWeek'

interface IProps {
  value?: Date
  disabledDay?: (day: DateTime) => boolean
  onChange?: (value: DateTime) => Promise<void> | void
}

export const DateSelect = ({ value = new Date(), disabledDay, onChange: handleChange }: IProps) => {
  const selectedDate: DateTime = DateTime.fromJSDate(value)
  const startOfTodaysWeek: DateTime = getStartOfWeek(DateTime.now())
  const startOfWeek: DateTime = getStartOfWeek(selectedDate)
  const endOfWeek: DateTime = getEndOfWeek(selectedDate)
  const daysOfWeek: DateTime[] = Interval.fromDateTimes(startOfWeek, endOfWeek)
    .splitBy({ day: 1 })
    .map((i) => i.start?.startOf('day'))
    .filter((d): d is DateTime => Boolean(d))
  return (
    <div
      className="row no-gutters"
      style={{
        marginRight: '-15px',
        marginLeft: '-15px',
      }}
    >
      <div className="col-12 text-center small d-block d-lg-none">
        {selectedDate.toLocaleString(DateTime.DATE_MED)}
      </div>
      <div className="col-1">
        {startOfTodaysWeek < startOfWeek && (
          <div className="d-flex h-100">
            <button
              type="button"
              className="btn btn-date-link p-0 m-auto"
              onClick={() => {
                const lastWeek: DateTime = selectedDate.minus({ week: 1 })
                handleChange?.(
                  DateTime.now().startOf('day') <= lastWeek
                    ? lastWeek
                    : DateTime.now().startOf('day'),
                )
              }}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </div>
        )}
      </div>
      <div className="col-10">
        <div className="row no-gutters text-center">
          {daysOfWeek.map((day: DateTime) => (
            <div
              key={day.toISODate()}
              className="col my-2"
              style={{ flex: '0 0 14.2857143%', maxWidth: '14.2857143%' }}
            >
              <button
                type="button"
                disabled={day < DateTime.now().startOf('day') || undefined}
                className="btn py-1 px-0 m-auto"
                onClick={() => {
                  handleChange?.(day)
                }}
              >
                {(() => {
                  if (selectedDate.hasSame(day, 'day')) {
                    return (
                      <>
                        <div className="d-none d-lg-inline-block">
                          <div className="text-primary text-uppercase">{day.toFormat('LLL')}</div>
                          <div className="text-primary h4 mb-0">{day.toFormat('d')}</div>
                          <div className="text-primary small">
                            {day.toFormat('ccc').toUpperCase()}
                          </div>
                        </div>
                        <div className="d-inline-block d-lg-none small">
                          <div className="text-primary small">
                            {day.toFormat('ccccc').toUpperCase()}
                          </div>
                          <div
                            className="btn-primary rounded-circle"
                            style={{ width: '1.5rem', height: '1.5rem', padding: '0.125rem 0' }}
                          >
                            {day.toFormat('d')}
                          </div>
                        </div>
                      </>
                    )
                  }
                  if (disabledDay?.(day)) {
                    return (
                      <>
                        <div className="d-none d-lg-inline-block">
                          <div className="text-muted text-uppercase">{day.toFormat('LLL')}</div>
                          <div className="mb-0 text-muted h4">{day.toFormat('d')}</div>
                          <div className="text-muted small">
                            {day.toFormat('ccc').toUpperCase()}
                          </div>
                        </div>
                        <div className="d-inline-block d-lg-none small">
                          <div className="text-muted small">
                            {day.toFormat('ccccc').toUpperCase()}
                          </div>
                          <div
                            className="text-muted rounded-circle"
                            style={{ width: '1.5rem', height: '1.5rem', padding: '0.125rem 0' }}
                          >
                            {day.toFormat('d')}
                          </div>
                        </div>
                      </>
                    )
                  }
                  return (
                    <>
                      <div className="d-none d-lg-inline-block">
                        <div className="text-primary text-uppercase">{day.toFormat('LLL')}</div>
                        <div className="mb-0 h4">{day.toFormat('d')}</div>
                        <div className="text-muted small">{day.toFormat('ccc').toUpperCase()}</div>
                      </div>
                      <div className="d-inline-block d-lg-none small">
                        <div className="text-muted small">
                          {day.toFormat('ccccc').toUpperCase()}
                        </div>
                        <div
                          className="rounded-circle"
                          style={{ width: '1.5rem', height: '1.5rem', padding: '0.125rem 0' }}
                        >
                          {day.toFormat('d')}
                        </div>
                      </div>
                    </>
                  )
                })()}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="col-1">
        <div className="d-flex h-100">
          <button type="button" className="btn btn-date-link p-0 m-auto">
            <FontAwesomeIcon
              icon={faAngleRight}
              onClick={async () => {
                handleChange?.(selectedDate.plus({ week: 1 }))
              }}
            />
          </button>
        </div>
      </div>
    </div>
  )
}
