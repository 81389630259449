import { GetServerSideProps } from 'next'
import Head from 'next/head'
import { useFetchCustomerProfile } from '../components/CustomerProfileView/hooks/useFetchCustomerProfile'
import { ErrorContent } from '../components/ErrorContent/ErrorContent'
import { HeaderNavigation } from '../components/HeaderNavigation/HeaderNavigation'
import { Layout } from '../components/Layout/Layout'
import { useProgram } from '../hooks/useFetchProgram'
import { getServerSideProgramProps } from '../server/getServerSideProgramProps'
import { DefaultBrandAccentColor } from '../utils/branding-utils'
import { toQueryParams } from '../utils/query-params'

interface IProps {
  errorCode: number
  message?: string
  embedded?: boolean
}

export const CitrusError = ({ errorCode, message, embedded }: IProps) => {
  const { program, isLoading: isLoadingProgram } = useProgram()
  const { data: customerProfile, isLoading: isLoadingCustomer } = useFetchCustomerProfile({
    programId: program?.id,
    redirect: false,
  })

  if (embedded) {
    const { preferences } = program || {
      preferences: {
        brand_accent_color: DefaultBrandAccentColor,
      },
    }
    const q = toQueryParams({
      program_id: program?.id,
      brand_accent_color: preferences?.brand_accent_color,
    })
    return (
      <div className="custom-branding">
        <Head>
          <link href={`/api/styles/branding.css?${q}`} rel="stylesheet" />
        </Head>
        <ErrorContent
          program={program}
          error={{ code: errorCode, message: '', timestamp: new Date().toJSON() }}
          hideButton={true}
        />
      </div>
    )
  }

  return (
    <Layout title="Error" program={program} authContainer={true} theme="light">
      <HeaderNavigation customerProfile={customerProfile} isLoading={isLoadingCustomer} />
      {(() => {
        if (isLoadingProgram) {
          return null
        }
        return (
          <>
            <div
              className="bg-error bg-white position-absolute top-0 left-0 h-100 w-100"
              style={{ opacity: 0.8 }}
            />
            <div className="account-container container" style={{ zIndex: 980 }}>
              <div id="error-page" className="row">
                <div className="col-12 text-center">
                  <ErrorContent
                    program={program}
                    error={{
                      code: errorCode,
                      message: message || '',
                      timestamp: new Date().toJSON(),
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )
      })()}
    </Layout>
  )
}

export const getServerSideProps: GetServerSideProps = getServerSideProgramProps

export default CitrusError
